<template>
  <div>Sewa Alat</div>
</template>

<script>
export default {

}
</script>

<style></style>